import { type RequestMeta } from "@koala/sdk";
import { QueryClient } from "@tanstack/react-query";
import { type GetServerSideProps } from "next";
import { initUrqlClient, type SSRData, withUrqlClient } from "next-urql";
import { ssrExchange } from "urql";
import { ROUTES } from "@/constants/routes";
import { getOrganization } from "@/features/configs/organization";
import LocationsFinder from "@/features/locations/finder";
import { contentSetupAPIRequests } from "@/services/content.service";
import { HasHomePageQueryDocument } from "@/types/graphql-schema";
import { getOrigin, getRequestOrigin } from "@/utils";

function Locations() {
  // @ts-expect-error
  return <LocationsFinder />;
}

interface PageProps {
  urqlState?: SSRData;
  dehydratedState?: unknown;
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  req,
  res,
}) => {
  if (!req.headers.host) {
    throw new Error("Missing request origin");
  }

  const queryClient = new QueryClient();
  const meta = getRequestOrigin(req.headers.host);
  const origin = getOrigin(req.headers.host);
  const ssrCache = ssrExchange({ isClient: false });
  const session = contentSetupAPIRequests(ssrCache, meta);
  const client = initUrqlClient(session, false);
  const organizationConfig = await getOrganization(queryClient, origin);
  const isSingleLocation =
    organizationConfig?.locations.features.isSingleLocation;

  if (client) {
    const { data } = await client
      .query(HasHomePageQueryDocument, {})
      .toPromise();
    const isSingleLocationEnabled = Boolean(
      isSingleLocation.enabled && isSingleLocation.storeLocationId
    );
    const hasCommercePlusPages = Boolean(data?.pages?.length);

    /**
     * If there is Commerce+ home page, redirect to web ordering application as defined in current block
     * Otherwise, redirect to web ordering application home page => index.tsx
     */
    if (hasCommercePlusPages && res) {
      /**
       * Redirect to store page if it is single store location organization/brand
       */
      if (isSingleLocationEnabled) {
        return {
          redirect: {
            destination: `/store/${isSingleLocation.storeLocationId}`,
            permanent: false,
          },
        };
      }
    } else {
      return {
        redirect: { permanent: false, destination: ROUTES.HOMEPAGE },
      };
    }
  }

  return {
    props: {
      urqlState: ssrCache.extractData(),
    },
  };
};

export default withUrqlClient(
  (ssrExchange, ctx) => {
    let meta: RequestMeta | undefined = undefined;
    if (typeof window !== "undefined") {
      meta = getRequestOrigin(window.location.host);
    } else if (ctx?.req?.headers.host) {
      meta = getRequestOrigin(ctx.req.headers.host);
    }
    return contentSetupAPIRequests(ssrExchange, meta);
  },
  { neverSuspend: true }
)(Locations);
